<template>
    <div class="withdrawalList">
        <div class="search">
            <a-input class="search-item" v-model="phoneNumber" placeholder="手机号码"></a-input>
            <a-input class="search-item" v-model="nickName" placeholder="昵称/姓名"></a-input>


            <a-select class="search-item" v-model="AuditStatus">
                <a-select-option value="">
                    全部
                </a-select-option>
                <a-select-option value="0">
                    未开始
                </a-select-option>
                <a-select-option value="1">
                    审核中
                </a-select-option>
                <a-select-option value="2">
                    已审核
                </a-select-option>
                <a-select-option value="3">
                    已驳回
                </a-select-option>

            </a-select>
            <a-select class="search-item" v-model="Status">
                <a-select-option value="">
                    全部
                </a-select-option>
                <a-select-option value="0">
                    已支付
                </a-select-option>
                <a-select-option value="1">
                    未支付
                </a-select-option>

            </a-select>
            <a-button class="search-item" @click="_reset()">重置</a-button>
            <a-button class="search-item" type="primary" @click="_search()">查询</a-button>

        </div>
        <a-table :pagination="false" :columns="tableColumns" :data-source="tableList">

            <div slot="Id" slot-scope="text, record">
                <span class="table-action" @click="_info(record.Id,record.nickName)">佣金记录</span>
                <a-popconfirm title="你确定审核通过嘛?" @confirm="_reject(record.Id,2)" okText="确定" cancelText="取消">
                    <span class="table-action">通过审核</span>
                </a-popconfirm>
                <a-popconfirm title="你确定驳回嘛?" @confirm="_reject(record.Id,3)" okText="确定" cancelText="取消">
                    <span class="table-action">驳回审核</span>
                </a-popconfirm>
            </div>
        </a-table>
        <a-pagination v-if="total" style="margin-top: 35px;text-align: right;" v-model="Page" @change="ChangePage" :pageSize="Limit" :total="total" show-less-items/>

        <a-drawer
                :title="visibleName"
                width="520"
                :closable="false"
                :visible="visible"
                @close="onClose"
        >

            <div
                    :style="{
          position: 'absolute',
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e8e8e8',
          padding: '10px 16px',
          textAlign: 'left',
          left: 0,
          background: '#fff',
          borderRadius: '0 0 4px 4px',
        }"
            >
                <a-button style="marginRight: 8px" @click="onClose">
                    取消
                </a-button>
                <a-button type="primary" @click="onClose">
                    确认
                </a-button>
            </div>
        </a-drawer>
    </div>
</template>

<script>
    export default {
        name: "withdrawalList",
        data() {
            return {
                tableColumns: [
                    {
                        title: '昵称/姓名',
                        dataIndex: 'nickName',
                        key: 'nickName',
                        align:'center',
                    },
                    {
                        title: '手机号码',
                        dataIndex: 'phoneNumber',
                        key: 'phoneNumber',
                        align:'center',
                    },
                    {
                        title: '提现金额',
                        dataIndex: 'Amount',
                        key: 'Amount',
                        align:'center',
                    },
                    {
                        title: '订单号',
                        dataIndex: 'PartnerTradeNo',
                        key: 'PartnerTradeNo',
                        align:'center',
                    },
                    {
                        title: '审核状态',
                        dataIndex: 'AuditStatus',
                        key: 'AuditStatus',
                        align:'center',
                    },


                    {
                        title: '状态',
                        dataIndex: 'Status',
                        key: 'Status',
                        align:'center',
                    },
                    {
                        title: '提现方式',
                        dataIndex: 'Type',
                        key: 'Type',
                        align:'center',
                    },
                    {
                        title: '备注',
                        dataIndex: 'Desc',
                        key: 'Desc',
                        align:'center',
                    },
                    {
                        title: '审核人',
                        dataIndex: 'ConfimUserName',
                        key: 'ConfimUserName',
                        align:'center',
                    },
                    {
                        title: '操作',
                        dataIndex: 'Id',
                        key: 'Id',
                        scopedSlots: {customRender: 'Id'},
                        align:'center',
                    },

                ],
                tableList: [],
                total: '',
                Page: 0,
                Limit: 10,
                Status: "",
                phoneNumber: '',
                nickName: '',
                AuditStatus: "",
                visible:false,
                visibleName:'',



            }
        },
        props: {
            UserId: {
                type: String,

            },
        },
        created() {
            this.loadData();
        },
        methods: {
            loadData() {
                this.$message.loading('加载中...', 0);
                let self = this;
                this.$axios.get(1248, {
                    pagesize: self.Limit,
                    p: self.Page,
                    phoneNumber: self.phoneNumber,
                    nickName: self.nickName,
                    Status: self.Status,
                    AuditStatus: self.AuditStatus,
                    UserId:self.UserId
                }, res => {
                    this.$message.destroy();
                    if (res.data.code == 0) {
                        self.tableList = res.data.data;
                        self.total = res.data.total;

                    } else {
                        self.tableList = [];
                        this.$message.error(res.data.msg)
                    }
                })
            },
            ChangePage(val) {
                this.Page = val;
                this.loadData();
            },
            _reset() {
                this.Page = 0;
                this.phoneNumber = '';
                this.nickName = '';
                this.Status = '';
                this.loadData();
            },
            _search() {
                this.Page = 0;
                this.loadData();
            },

            _reject(id,type) {
                let self = this;
                this.$message.loading('操作中....', 0);
                this.$axios.get(1249, {
                    Id: id,
                    AuditStatus:type
                }, res => {
                    this.$message.destroy();
                    if (res.data.code == 0) {
                        this.$message.success(res.data.msg);
                        self.loadData();
                    } else {
                        this.$message.success(res.data.msg);
                    }
                })
            },
            _info(id,name) {
                this.visibleName = name+'的佣金记录';
                this.visible = true;
            },
            onClose() {
                this.visibleName ='';
                this.visible = false;
            },
        }
    }
</script>

<style scoped lang="less">
    .withdrawalList {
        padding: 20px;
        background: #ffffff;
    }

    .search {
        display: flex;
        margin-bottom: 20px;
        .search-item {
            width: 156px;
            margin-right: 10px;
        }
    }

    .table-action {
        color: #2994FF;
        font-size: 13px;
        margin-right: 31px;
        cursor: pointer;
    }
</style>