import { render, staticRenderFns } from "./distributionInfo.vue?vue&type=template&id=272a4981&scoped=true&"
import script from "./distributionInfo.vue?vue&type=script&lang=js&"
export * from "./distributionInfo.vue?vue&type=script&lang=js&"
import style0 from "./distributionInfo.vue?vue&type=style&index=0&id=272a4981&prod&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "272a4981",
  null
  
)

export default component.exports