<template>
    <div class="distributionInfo">
        <div class="title">基本信息</div>
        <div class="info">
            <div class="info-left">
                <a-avatar shape="square" :size="160" :src="infoData.avatarUrl"/>
                <div class="info-left-datail">
                    <!--<div class="info-left-item">-->
                    <!--<div>ID：20462</div>-->
                    <!--<div class="blacklist">加入黑名单</div>-->
                    <!--</div>-->
                    <div class="info-left-item">昵称：{{infoData.nickName}}</div>
                    <div class="info-left-item"> 真实姓名：{{infoData.nickName}}</div>
                    <div class="info-left-item">手机号码：{{infoData.phoneNumber}}</div>
                    <div class="info-left-item">
                        <span>分销等级：</span>
                        <a-select style="width: 120px" label-in-value @change="changeLevel" v-if="levelList.length>0"  :default-value="{ key: infoData.LevelId}" >
                            <a-select-option  v-for="item in levelList" :value="item.Id">{{item.Name}}</a-select-option>

                        </a-select>
                    </div>
                    <a-button class="info-left-item" style="color: #ffffff" type="primary" @click="saveLevel()">保 存</a-button>
                </div>

            </div>
            <div class="info-right">
                <div class="info-right-item"> 推荐人：{{infoData.parentUserName}}</div>
                <div class="info-right-item">推广订单：{{infoData.distribution_count}}</div>
                <div class="info-right-item">推广订单金额：{{infoData.distribution_money}}</div>
                <div class="info-right-item"> 佣金总额：{{infoData.CashOut}}</div>
                <div class="info-right-item">已提佣金：{{infoData.withdrawal_money}}</div>
                <div class="info-right-item">申请时间：{{infoData.CreateTime}}</div>
                <div class="info-right-item">成为分销商时间：{{infoData.CreateTime}}</div>

            </div>
        </div>
        <a-tabs  v-model="tabKey"  @change="callback">
            <a-tab-pane key="1" :tab="'下级列表('+total+')'">
                <a-table v-if="tableList.length>0" :pagination="false" :columns="tableColumns" :data-source="tableList">
                    <div slot="nickName" slot-scope="text, record">
                        <a-avatar :src="record.avatarUrl"/>
                        <div>{{record.nickName}}</div>
                    </div>
                    <div slot="phoneNumber" slot-scope="text, record">
                        <div>{{record.businessCardName}}</div>
                        <div>{{record.phoneNumber}}</div>
                    </div>
                    <div slot="LevelName" slot-scope="text, record">
                        <div>{{record.LevelName}}</div>
                        <div>{{record.count}}</div>
                    </div>
                    <div slot="CashOut" slot-scope="text, record">
                        <div class="CumulativeIncome">可用: {{record.CumulativeIncome}}元</div>
                        <div class="CashOut">可提现:{{record.CashOut}}元</div>
                    </div>
                </a-table>
                <a-pagination v-if="total" style="margin-top: 35px;text-align: right;" v-model="Page" @change="ChangePage" :pageSize="Limit" :total="total" show-less-items/>
            </a-tab-pane>
            <a-tab-pane key="2" tab="订单详情" force-render>
                <orderInfo v-if="tabKey==2" :UserId="id"></orderInfo>
            </a-tab-pane>
            <a-tab-pane key="3" tab="提现记录">
                <withdrawalList :UserId="id"></withdrawalList>
            </a-tab-pane>
        </a-tabs>
    </div>
</template>

<script>
    import orderInfo from 'pages/DistributionAdmin/orderInfo';
    import withdrawalList from 'pages/DistributionAdmin/withdrawalList';

    export default {
        components: {orderInfo,withdrawalList},
        name: "distributionInfo",
        data() {
            return {
                id: this.$route.query.id,
                tabKey: this.$route.query.tabKey?this.$route.query.tabKey:1,
                infoData: '',
                tableList: [],
                tableColumns: [

                    {
                        title: '分销员',
                        dataIndex: 'nickName',
                        align: 'center',
                        key: 'nickName',
                        scopedSlots: {customRender: 'nickName'},
                    },
                    {
                        title: '姓名/手机',
                        dataIndex: 'phoneNumber',
                        align: 'center',
                        key: 'phoneNumber',
                        scopedSlots: {customRender: 'phoneNumber'},
                    },
                    {
                        title: '分销等级/下级人数',
                        dataIndex: 'LevelName',
                        align: 'center',
                        key: 'LevelName',
                        scopedSlots: {customRender: 'LevelName'},
                    },
                    {
                        title: '可用佣金/已提现佣金',
                        dataIndex: 'CashOut',
                        align: 'center',
                        key: 'CashOut',
                        scopedSlots: {customRender: 'CashOut'},
                        width: 240,
                    },


                ],
                total: 0,
                Page: 0,
                Limit: 10,
                levelList: [],
                LevelId:'',
                LevelName:'',

            }
        },
        created() {
            this.loadData();
            this._userOffline();


        },
        methods: {
            _levelList() {
                let self = this;
                this.$axios.get(1245, {}, res => {
                    if (res.data.code == 0) {
                        self.levelList = res.data.data;

                    }
                })
            },
            changeLevel(e) {
                this.LevelName = e.label;
                this.LevelId = e.key;
            },
            saveLevel(){
                if(!this.LevelId){
                    this.$message.error('请先选择等级');
                    return false;
                }
                let self = this;
                this.$axios.get(1246,{
                    LevelName:self.LevelName,
                    LevelId:self.LevelId,  
                    UserId:self.id
                },res=>{
                    if(res.data.code==0){
                        this.$message.success(res.data.msg);
                    }else{
                        this.$message.error(res.data.msg);
                    }
                })
            },
            _userOffline() {
                let self = this;
                this.$axios.get(1238, {
                    ParentUserId: self.id,
                    pagesize: self.Limit,
                    p: self.Page
                }, res => {
                    if (res.data.code == 0) {
                        self.total = res.data.total;
                        self.tableList = res.data.data;

                    } else {
                        self.total = 0;
                        self.tableList = [];
                        this.$message.error(res.data.msg);
                    }

                })
            },
            ChangePage(value) {
                this.Page = value;
                this._userOffline();
            },
            loadData() {
                let self = this;
                this.$message.loading('加载中...', 0);
                this.$axios.get(1237, {
                    Id: self.id,
                }, res => {
                    this.$message.destroy();
                    if (res.data.code == 0) {
                        self.infoData = res.data.data;
                        self._levelList();

                    }

                })
            },
            callback(key) {
                this.tabKey = key;
            },
        }
    }
</script>

<style scoped lang="less">
    .distributionInfo {
        width: 100%;
        background: #ffffff;
        padding: 20px;
        .title {
            width: 100%;
            border-bottom: 1px solid #DDDDDD;
            position: relative;
            padding: 10px 0px;
            color: #1D1D1D;
            font-size: 14px;
        }
        .title::after {
            content: '';
            width: 48px;
            height: 3px;
            background: #2994FF;
            position: absolute;
            left: 0;
            bottom: 0;
        }
        .info {
            display: flex;
            margin-top: 25px;

            .info-left {
                display: flex;
                width: 50%;
                .info-left-datail {
                    margin-left: 20px;
                    .info-left-item {
                        margin-bottom: 15px;
                        color: #1D1D1D;
                        font-size: 12px;
                        display: flex;
                        align-items: center;
                        .blacklist {
                            color: #2994FF;
                            font-size: 12px;
                            margin-left: 15px;
                            cursor: pointer;
                            font-weight: 600;
                        }
                    }
                }

            }
            .info-right {
                width: 50%;
                .info-right-item {
                    color: #1D1D1D;
                    font-size: 12px;
                    margin-bottom: 15px;
                }

            }
        }

    }

    .CumulativeIncome {
        margin: auto;
        background: #75C255;
        border-radius: 2px;
        color: #ffffff;
        font-size: 12px;
        padding: 0px 5px;
        width: 60%;
        margin-bottom: 5px;

        border-radius: 2px;
    }

    .CashOut {
        background: #FB6638;
        color: #ffffff;
        font-size: 12px;
        padding: 0px 5px;
        width: 70%;
        margin: auto;
        border-radius: 2px;
    }
</style>